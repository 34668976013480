var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "container-layout details-document-package px-0",
            class: {
              "container-layout": _vm.$screen.width > 576,
              "container-mobile": _vm.$screen.width <= 576,
            },
          },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _vm.TsDays
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", xl: "12" } },
                          [
                            _c("transition", { attrs: { name: "slide" } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-card",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c(
                                        "b-card-body",
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "alert alert-info",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(this.caption2)
                                                      ),
                                                    ]
                                                  ),
                                                  this.disableSave
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "alert alert-warning",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  369,
                                                                  "You must choose at least one of the options (Use hours, Define mileage to set)"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { xl: "6", md: "6" } },
                                                [
                                                  _c("Calendar", {
                                                    attrs: {
                                                      "max-date": this.nextYear,
                                                      locale: _vm.getCurLangStr,
                                                      attributes: _vm.attrs,
                                                      columns: 1,
                                                      "disabled-dates": [
                                                        {
                                                          start: _vm.tomorrow,
                                                          end: null,
                                                        },
                                                      ],
                                                      "is-expanded": "",
                                                    },
                                                    on: {
                                                      dayclick:
                                                        _vm.calDateSelected,
                                                      "update:from-page":
                                                        _vm.calPageUpdated,
                                                    },
                                                    model: {
                                                      value: _vm.attrs[0].dates,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.attrs[0],
                                                          "dates",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "attrs[0].dates",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { xl: "6", md: "6" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          21,
                                                          "Date:"
                                                        ),
                                                        "label-for": "date",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          disabled: "",
                                                          value: _vm
                                                            .moment(
                                                              _vm.curDay
                                                                .stageDay.date
                                                            )
                                                            .format(
                                                              "DD/MM/YYYY"
                                                            ),
                                                          type: "text",
                                                          id: "date",
                                                        },
                                                        on: {
                                                          input: _vm.updateDate,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("hr"),
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            staticClass:
                                              "divider-line mt-3 mb-3",
                                          }),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                                id: "chkUseHours",
                                                                value: true,
                                                                "unchecked-value": false,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.curDay
                                                                    .stageDay
                                                                    .useHours,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.curDay
                                                                        .stageDay,
                                                                      "useHours",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "curDay.stageDay.useHours",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      139,
                                                                      "Use hours"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-collapse",
                                                    {
                                                      attrs: {
                                                        visible:
                                                          _vm.curDay.stageDay
                                                            .useHours,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "scheduler-border border-color-blue-streak",
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "scheduler-border text-color-blue-streak fs-16",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      268,
                                                                      "1st shooting"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "pb-4",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "2",
                                                                    lg: "2",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 0,
                                                                        "start-forward-day": 0,
                                                                        "end-backward-day": 0,
                                                                        "end-forward-day": 0,
                                                                        "hide-end-date":
                                                                          "",
                                                                        "hide-after":
                                                                          "",
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .strTime,
                                                                              "startDateStrTime"
                                                                            ),
                                                                          end: "2019-01-01T00:00:00Z",
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            26,
                                                                            "Start:"
                                                                          ),
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .strTime,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "strTime",
                                                                              "startDateStrTime"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "5",
                                                                    lg: "5",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 1,
                                                                        "start-forward-day": 1,
                                                                        "end-backward-day": 1,
                                                                        "end-forward-day": 2,
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .lunchStrTime,
                                                                              "startDateLunchStrTime"
                                                                            ),
                                                                          end: _vm.formatTimeWithStageDayDate(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .lunchEndTime,
                                                                            "startDateLunchEndTime"
                                                                          ),
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            29,
                                                                            "Lunch Start:"
                                                                          ),
                                                                        "end-label":
                                                                          _vm.FormMSG(
                                                                            30,
                                                                            "Lunch End:"
                                                                          ),
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .lunchStrTime,
                                                                        "state-end-date":
                                                                          _vm
                                                                            .errorState
                                                                            .lunchEndTime,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "lunchStrTime",
                                                                              "startDateLunchStrTime"
                                                                            )
                                                                          },
                                                                        "change-time-end":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "lunchEndTime",
                                                                              "startDateLunchEndTime"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "5",
                                                                    lg: "5",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 1,
                                                                        "start-forward-day": 1,
                                                                        "end-backward-day": 1,
                                                                        "end-forward-day": 1,
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .endTime,
                                                                              "startDateEndTime"
                                                                            ),
                                                                          end: _vm.formatTimeWithStageDayDate(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .actualEndTime,
                                                                            "startDateActualEndTime"
                                                                          ),
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            127,
                                                                            "Expected end"
                                                                          ),
                                                                        "end-label":
                                                                          _vm.FormMSG(
                                                                            128,
                                                                            "Actual end"
                                                                          ),
                                                                        "sync-start-end":
                                                                          "",
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .endTime,
                                                                        "state-end-date":
                                                                          _vm
                                                                            .errorState
                                                                            .actualEndTime,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "endTime",
                                                                              "startDateEndTime"
                                                                            )
                                                                          },
                                                                        "change-time-end":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "actualEndTime",
                                                                              "startDateActualEndTime"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "scheduler-border border-color-blue-streak",
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "scheduler-border text-color-blue-streak fs-16 d-flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  attrs: {
                                                                    size: "lg",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.curDay
                                                                        .stageDay
                                                                        .is2NdShooting,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .curDay
                                                                            .stageDay,
                                                                          "is2NdShooting",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "curDay.stageDay.is2NdShooting",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "1px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        269,
                                                                        "2nd shooting"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "pb-4",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "2",
                                                                    lg: "2",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 0,
                                                                        "start-forward-day": 0,
                                                                        "end-backward-day": 0,
                                                                        "end-forward-day": 0,
                                                                        "hide-end-date":
                                                                          "",
                                                                        "hide-after":
                                                                          "",
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .strTime2,
                                                                              "startDateStrTime2"
                                                                            ),
                                                                          end: "2019-01-01T00:00:00Z",
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave ||
                                                                          !_vm
                                                                            .curDay
                                                                            .stageDay
                                                                            .is2NdShooting,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            371,
                                                                            "Start"
                                                                          ),
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .strTime2,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "strTime2",
                                                                              "startDateStrTime2"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "5",
                                                                    lg: "5",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 1,
                                                                        "start-forward-day": 1,
                                                                        "end-backward-day": 1,
                                                                        "end-forward-day": 2,
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .lunchStrTime2,
                                                                              "startDateLunchStrTime2"
                                                                            ),
                                                                          end: _vm.formatTimeWithStageDayDate(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .lunchEndTime2,
                                                                            "startDateLunchEndTime2"
                                                                          ),
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave ||
                                                                          !_vm
                                                                            .curDay
                                                                            .stageDay
                                                                            .is2NdShooting,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            372,
                                                                            "Lunch Start"
                                                                          ),
                                                                        "end-label":
                                                                          _vm.FormMSG(
                                                                            373,
                                                                            "Lunch End"
                                                                          ),
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .lunchStrTime2,
                                                                        "state-end-date":
                                                                          _vm
                                                                            .errorState
                                                                            .lunchEndTime2,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "lunchStrTime2",
                                                                              "startDateLunchStrTime2"
                                                                            )
                                                                          },
                                                                        "change-time-end":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "lunchEndTime2",
                                                                              "startDateLunchEndTime2"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "5",
                                                                    lg: "5",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 1,
                                                                        "start-forward-day": 1,
                                                                        "end-backward-day": 1,
                                                                        "end-forward-day": 1,
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .endTime2,
                                                                              "startDateEndTime2"
                                                                            ),
                                                                          end: _vm.formatTimeWithStageDayDate(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .actualEndTime2,
                                                                            "startDateActualEndTime2"
                                                                          ),
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave ||
                                                                          !_vm
                                                                            .curDay
                                                                            .stageDay
                                                                            .is2NdShooting,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            374,
                                                                            "Expected end"
                                                                          ),
                                                                        "end-label":
                                                                          _vm.FormMSG(
                                                                            375,
                                                                            "Actual end"
                                                                          ),
                                                                        "sync-start-end":
                                                                          "",
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .endTime2,
                                                                        "state-end-date":
                                                                          _vm
                                                                            .errorState
                                                                            .actualEndTime2,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "endTime2",
                                                                              "startDateEndTime2"
                                                                            )
                                                                          },
                                                                        "change-time-end":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "actualEndTime2",
                                                                              "startDateActualEndTime2"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "fieldset",
                                                        {
                                                          staticClass:
                                                            "scheduler-border border-color-blue-streak",
                                                        },
                                                        [
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "scheduler-border text-color-blue-streak fs-16 d-flex align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  attrs: {
                                                                    size: "lg",
                                                                    disabled:
                                                                      !_vm
                                                                        .curDay
                                                                        .stageDay
                                                                        .is2NdShooting,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.curDay
                                                                        .stageDay
                                                                        .is3RdShooting,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .curDay
                                                                            .stageDay,
                                                                          "is3RdShooting",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "curDay.stageDay.is3RdShooting",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "1px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        370,
                                                                        "3rd shooting"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "pb-4",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "2",
                                                                    lg: "2",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 0,
                                                                        "start-forward-day": 0,
                                                                        "end-backward-day": 0,
                                                                        "end-forward-day": 0,
                                                                        "hide-end-date":
                                                                          "",
                                                                        "hide-after":
                                                                          "",
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .strTime3,
                                                                              "startDateStrTime3"
                                                                            ),
                                                                          end: "2019-01-01T00:00:00Z",
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave ||
                                                                          !_vm
                                                                            .curDay
                                                                            .stageDay
                                                                            .is3RdShooting,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            376,
                                                                            "Start"
                                                                          ),
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .strTime3,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "strTime3",
                                                                              "startDateStrTime3"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "5",
                                                                    lg: "5",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 1,
                                                                        "start-forward-day": 1,
                                                                        "end-backward-day": 1,
                                                                        "end-forward-day": 2,
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .lunchStrTime3,
                                                                              "startDateLunchStrTime3"
                                                                            ),
                                                                          end: _vm.formatTimeWithStageDayDate(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .lunchEndTime3,
                                                                            "startDateLunchEndTime3"
                                                                          ),
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave ||
                                                                          !_vm
                                                                            .curDay
                                                                            .stageDay
                                                                            .is3RdShooting,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            377,
                                                                            "Lunch Start"
                                                                          ),
                                                                        "end-label":
                                                                          _vm.FormMSG(
                                                                            378,
                                                                            "Lunch End"
                                                                          ),
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .lunchStrTime3,
                                                                        "state-end-date":
                                                                          _vm
                                                                            .errorState
                                                                            .lunchEndTime3,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "lunchStrTime3",
                                                                              "startDateLunchStrTime3"
                                                                            )
                                                                          },
                                                                        "change-time-end":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "lunchEndTime3",
                                                                              "startDateLunchEndTime3"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    xl: "5",
                                                                    lg: "5",
                                                                    md: "12",
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "StartEndTimeOverRange",
                                                                    {
                                                                      attrs: {
                                                                        "start-backward-day": 1,
                                                                        "start-forward-day": 1,
                                                                        "end-backward-day": 1,
                                                                        "end-forward-day": 1,
                                                                        value: {
                                                                          start:
                                                                            _vm.formatTimeWithStageDayDate(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .endTime3,
                                                                              "startDateEndTime3"
                                                                            ),
                                                                          end: _vm.formatTimeWithStageDayDate(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .actualEndTime3,
                                                                            "startDateActualEndTime3"
                                                                          ),
                                                                          date: _vm.getStrDateToDateTime(
                                                                            _vm
                                                                              .curDay
                                                                              .stageDay
                                                                              .date
                                                                          ),
                                                                        },
                                                                        "disable-save":
                                                                          _vm.disableSave ||
                                                                          !_vm
                                                                            .curDay
                                                                            .stageDay
                                                                            .is3RdShooting,
                                                                        "start-label":
                                                                          _vm.FormMSG(
                                                                            379,
                                                                            "Expected end"
                                                                          ),
                                                                        "end-label":
                                                                          _vm.FormMSG(
                                                                            380,
                                                                            "Actual end"
                                                                          ),
                                                                        "sync-start-end":
                                                                          "",
                                                                        "state-start-date":
                                                                          _vm
                                                                            .errorState
                                                                            .endTime3,
                                                                        "state-end-date":
                                                                          _vm
                                                                            .errorState
                                                                            .actualEndTime3,
                                                                      },
                                                                      on: {
                                                                        "change-time-start":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "endTime3",
                                                                              "startDateEndTime3"
                                                                            )
                                                                          },
                                                                        "change-time-end":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleChangeTime(
                                                                              $event,
                                                                              "actualEndTime3",
                                                                              "startDateActualEndTime3"
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-row",
                                                        { staticClass: "mt-3" },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        32,
                                                                        "Comment:"
                                                                      ),
                                                                    "label-for":
                                                                      "comment",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-textarea",
                                                                    {
                                                                      attrs: {
                                                                        disabled: false,
                                                                        id: "comment",
                                                                        rows: "2",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .curDay
                                                                            .stageDay
                                                                            .comment,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay,
                                                                              "comment",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "curDay.stageDay.comment",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            staticClass:
                                              "divider-line mt-3 mb-3",
                                          }),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    md: "6",
                                                    lg: "6",
                                                    xl: "6",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "mb-3" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb pb-1",
                                                              attrs: {
                                                                size: "lg",
                                                                id: "chkMileageToSet",
                                                                value: true,
                                                                "unchecked-value": false,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.curDay
                                                                    .stageDay
                                                                    .useMileageToSet,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.curDay
                                                                        .stageDay,
                                                                      "useMileageToSet",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "curDay.stageDay.useMileageToSet",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      131,
                                                                      "Define mileage to set"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-collapse",
                                                    {
                                                      attrs: {
                                                        visible:
                                                          _vm.curDay.stageDay
                                                            .useMileageToSet,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        132,
                                                                        "Description"
                                                                      ),
                                                                    "label-for":
                                                                      "description",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-textarea",
                                                                    {
                                                                      attrs: {
                                                                        disabled: false,
                                                                        id: "description",
                                                                        rows: "2",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .curDay
                                                                            .stageDay
                                                                            .kmToSetDescription,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay,
                                                                              "kmToSetDescription",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "curDay.stageDay.kmToSetDescription",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "6",
                                                                lg: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        134,
                                                                        "Mileage to set definition"
                                                                      ),
                                                                    "label-for":
                                                                      "lunchEndTime",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-select",
                                                                    {
                                                                      attrs: {
                                                                        options:
                                                                          _vm.optionsMileageDefinition,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.handleChangeSetDefinition,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.mileageDefinition,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.mileageDefinition =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "mileageDefinition",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                md: "5",
                                                                lg: "5",
                                                                xs: "10",
                                                                sm: "10",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.labelInputMileageDefinition,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-input-group",
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm.valueMileageDefinition,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.valueMileageDefinition =
                                                                                    $$v
                                                                                },
                                                                              expression:
                                                                                "valueMileageDefinition",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-input-group-append",
                                                                        [
                                                                          _c(
                                                                            "b-input-group-text",
                                                                            [
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .kmToSetDefined
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "font-weight-bold",
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "#06263e",
                                                                                          "font-size":
                                                                                            "1rem",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                          _vm._s(
                                                                                            _vm.distanceUnit
                                                                                          ) +
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .curDay
                                                                                .stageDay
                                                                                .hoursDefined
                                                                                ? _c(
                                                                                    "currency-svg",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "#06263E",
                                                                                          opacity:
                                                                                            "0.85",
                                                                                          width:
                                                                                            "15",
                                                                                          height:
                                                                                            "15",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "3",
                                                    offset: "9",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        block: "",
                                                        variant:
                                                          "success pull-left",
                                                        disabled:
                                                          _vm.disableSave,
                                                        size: "md",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.curDaySave()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            this.FormMSG(
                                                              33,
                                                              "Save modification"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-modal",
                                            {
                                              staticClass: "modal-success",
                                              attrs: {
                                                "header-class":
                                                  "header-class-modal-doc-package",
                                                title: _vm.FormMSG(
                                                  34,
                                                  "Success!"
                                                ),
                                                "ok-variant": "success",
                                                "ok-only": "",
                                                "modal-class": "mui-animation",
                                                fade: false,
                                              },
                                              on: {
                                                ok: function ($event) {
                                                  _vm.successModal = false
                                                },
                                              },
                                              model: {
                                                value: _vm.successModal,
                                                callback: function ($$v) {
                                                  _vm.successModal = $$v
                                                },
                                                expression: "successModal",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      35,
                                                      "Your information has been saved"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-modal",
                                            {
                                              staticClass: "modal-success",
                                              attrs: {
                                                "header-class":
                                                  "header-class-modal-doc-package",
                                                title: _vm.FormMSG(
                                                  34,
                                                  "Success!"
                                                ),
                                                "ok-variant": "success",
                                                "ok-only": "",
                                                "modal-class": "mui-animation",
                                                fade: false,
                                              },
                                              on: {
                                                ok: function ($event) {
                                                  _vm.successModalDelete = false
                                                },
                                              },
                                              model: {
                                                value: _vm.successModalDelete,
                                                callback: function ($$v) {
                                                  _vm.successModalDelete = $$v
                                                },
                                                expression:
                                                  "successModalDelete",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      36,
                                                      "Your information has been deleted."
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-card",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c(
                                        "b-card-body",
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    xl: "12",
                                                  },
                                                },
                                                [
                                                  _c("h2", [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.FormMSG(
                                                          38,
                                                          "Stage days of "
                                                        )
                                                      ) +
                                                        "   " +
                                                        _vm._s(
                                                          _vm.getMonthAndYear(
                                                            this.curDay.stageDay
                                                              .date
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                  _vm.getRowCount(this.TsDays) >
                                                  0
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  37,
                                                                  "Click on a row to view a day"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _vm.$screen.width <
                                                          992
                                                            ? _c(
                                                                "CardListBuilder",
                                                                {
                                                                  attrs: {
                                                                    items:
                                                                      _vm.TsDaysTable,
                                                                    fields:
                                                                      _vm.tsfields,
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "actions",
                                                                          fn: function (
                                                                            data
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "none",
                                                                                      size: "sm",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.rowClicked(
                                                                                          data.item
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      _vm
                                                                                        .ICONS
                                                                                        .EDIT
                                                                                        .name
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .EDIT
                                                                                              .color,
                                                                                          size: 20,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "none",
                                                                                      size: "sm",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.delItem(
                                                                                          data
                                                                                            .item
                                                                                            .stageDay
                                                                                            .id
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    _vm.getLucideIcon(
                                                                                      _vm
                                                                                        .ICONS
                                                                                        .TRASH
                                                                                        .name
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            _vm
                                                                                              .ICONS
                                                                                              .TRASH
                                                                                              .color,
                                                                                          size: 20,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      843931776
                                                                    ),
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm.$screen.width >=
                                                          992
                                                            ? _c("b-table", {
                                                                ref: "myTable",
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                },
                                                                attrs: {
                                                                  hover:
                                                                    _vm.hover,
                                                                  responsive:
                                                                    "sm",
                                                                  selectable:
                                                                    "",
                                                                  selectedVariant:
                                                                    _vm.selectedColor,
                                                                  "select-mode":
                                                                    _vm.selectMode,
                                                                  items:
                                                                    _vm.TsDaysTable,
                                                                  fields:
                                                                    _vm.tsfields,
                                                                  "current-page":
                                                                    _vm.currentPage,
                                                                  "per-page":
                                                                    _vm.perPage,
                                                                  "head-variant":
                                                                    _vm.hv,
                                                                  bordered: "",
                                                                  small: "",
                                                                  "sticky-header":
                                                                    "700px",
                                                                },
                                                                on: {
                                                                  "row-clicked":
                                                                    _vm.rowClicked,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "cell(validatedStatus)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                class:
                                                                                  [
                                                                                    "badge badge-" +
                                                                                      data
                                                                                        .item
                                                                                        .validatedClass,
                                                                                  ],
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    data
                                                                                      .item
                                                                                      .validatedStatus
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(rem)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "b-button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    variant:
                                                                                      "none",
                                                                                    size: "sm",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.delItem(
                                                                                        data
                                                                                          .item
                                                                                          .stageDay
                                                                                          .id
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  _vm.getLucideIcon(
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .TRASH
                                                                                      .name
                                                                                  ),
                                                                                  {
                                                                                    tag: "component",
                                                                                    attrs:
                                                                                      {
                                                                                        color:
                                                                                          _vm
                                                                                            .ICONS
                                                                                            .TRASH
                                                                                            .color,
                                                                                        size: 20,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(stageDayEndTime)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex justify-content-center align-items-center",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "adjust-icon-ts-tsd",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.getLabelDate(
                                                                                            data.item,
                                                                                            "endTime",
                                                                                            "startDateEndTime"
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(stageDayActualEndTime)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex justify-content-center align-items-center",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "adjust-icon-ts-tsd",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.getLabelDate(
                                                                                            data.item,
                                                                                            "actualEndTime",
                                                                                            "startDateActualEndTime"
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(stageDayLunchStrTime)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex justify-content-center align-items-center",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "adjust-icon-ts-tsd",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.getLabelDate(
                                                                                            data.item,
                                                                                            "lunchStrTime",
                                                                                            "startDateLunchStrTime"
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                      {
                                                                        key: "cell(stageDayLunchEndTime)",
                                                                        fn: function (
                                                                          data
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex justify-content-center align-items-center",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "adjust-icon-ts-tsd",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.getLabelDate(
                                                                                            data.item,
                                                                                            "lunchEndTime",
                                                                                            "startDateLunchEndTime"
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    3659718275
                                                                  ),
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.getRowCount(this.TsDays) <
                                                  1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "empty-state",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                this.FormMSG(
                                                                  42,
                                                                  "No stage days for this month"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }